<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Suspend subscription"
    @cancel="handleCancel"
    cancelText="Close"
    :cancel="true"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Suspend player</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
      <h2 class="font-bold my-4">
        Are you sure you want to suspend this player?
      </h2>
    </template>
    <template v-slot:body>
      <div v-if="isLoadingData" class="flex justify-center items-center py-4">
        <em class="fa fa-spinner fa-spin"></em>
      </div>
      <div v-else>
        <div>
          <h1>
            {{ playerName }} will have access to the VR platform until
            <span class="font-bold">{{ subscriptionExpiryDate }}. </span>
            All the user information will remain available on the web platform.
          </h1>
        </div>
        <div class="bg-gray-100 p-4 my-3 rounded-lg">
          <div class="flex items-center space-x-3">
            <img src="@/assets/icons/Info.svg" alt="Info" class="h-6 w-6" />
            <p v-if="platform === 'Stripe'" class="text-gray-400 text-sm">
              The new amount to be paid on the next invoice will be
              <span class="font-bold">${{ upcomingTotalInvoce }}</span>
            </p>
            <p v-else class="text-gray-400 text-sm">
              Remember to update your subscription in your
              {{
                platform == "Apple"
                  ? "Apple ID settings"
                  : "Mastermind app settings"
              }}
              after deactivating the user.
            </p>
          </div>
        </div>
        <div class="mt-4">
          <input
            type="checkbox"
            v-model="isConfirmChecked"
            @change="toggleConfirmButton"
          />
          <span class="ml-2">
            I understand, and I want to suspend this player.
          </span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";
import {
  subscriptionNextInvoiceTotalBeforeQuantityChange,
  getStripeSubscriptionInfo,
} from "@/services/stripe/stripe.js";

const props = defineProps({
  show: Boolean,
  playerName: String,
  isLoading: Boolean,
  platform: {
    type: String,
    default: "Stripe",
  },
  expiryDate: String,
});

const emit = defineEmits(["update:show", "confirm"]);

const isConfirmDisabled = ref(true);
const isConfirmChecked = ref(false);
const isLoadingData = ref(true);
const subscriptionExpiryDate = ref("");
const upcomingTotalInvoce = ref(0);

async function fetchNextInvoiceTotal() {
  if (props.platform === "Stripe") {
    const nextInvoiceTotal =
      await subscriptionNextInvoiceTotalBeforeQuantityChange("decrease");
    const subscription = await getStripeSubscriptionInfo();
    subscriptionExpiryDate.value = subscription.subscription_end_date;
    upcomingTotalInvoce.value = nextInvoiceTotal.next_invoice_total;
  } else if (props.platform === "Apple" || props.platform === "Google") {
    subscriptionExpiryDate.value = props.expiryDate;
  }

  isLoadingData.value = false;
}

function handleConfirm() {
  emit("confirm", subscriptionExpiryDate.value);
}

function handleCancel() {
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;
  emit("update:show", false);
}

function toggleConfirmButton() {
  isConfirmDisabled.value = !isConfirmChecked.value;
}

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      fetchNextInvoiceTotal();
    }
  }
);

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>

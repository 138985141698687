<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Add player"
    @cancel="handleCancel"
    cancelText="Close"
    :cancel="true"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Add a new player</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
      <h2 class="font-bold my-4">Add a new user to your subscription</h2>
    </template>
    <template v-slot:body>
      <div v-if="isLoadingData" class="flex justify-center items-center py-4">
        <em class="fa fa-spinner fa-spin"></em>
      </div>
      <div v-else>
        <div>
          <h1>
            This player will be granted access to your subscription's benefits
            once added. You can remove them at any point.
          </h1>
        </div>
        <div class="bg-gray-100 p-4 my-3 rounded-lg">
          <div class="flex items-center space-x-3">
            <img src="@/assets/icons/Info.svg" alt="Info" class="h-6 w-6" />
            <p class="text-gray-400 text-sm">
              The new amount to be paid on the next invoice will be
              <span class="font-bold">${{ upcomingTotalInvoce }}.</span>
              By adding a new player, you will be charged proportionally for the
              additional service until your next billing date based on your
              subscription rate.
            </p>
          </div>
        </div>
        <div class="mt-4">
          <input
            type="checkbox"
            v-model="isConfirmChecked"
            @change="toggleConfirmButton"
          />
          <span class="ml-2">
            I understand, and I want to add this player.
          </span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";
import { subscriptionNextInvoiceTotalBeforeQuantityChange } from "@/services/stripe/stripe.js";

const props = defineProps({
  show: Boolean,
  isLoading: Boolean,
});

const emit = defineEmits(["update:show", "confirm"]);

const isConfirmDisabled = ref(true);
const isConfirmChecked = ref(false);
const isLoadingData = ref(true);
const upcomingTotalInvoce = ref(0);

async function fetchNextInvoiceTotal() {
  const nextInvoiceTotal =
    await subscriptionNextInvoiceTotalBeforeQuantityChange("increase", "none");
  upcomingTotalInvoce.value = nextInvoiceTotal.next_invoice_total;
  isLoadingData.value = false;
}

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;
  emit("update:show", false);
}

function toggleConfirmButton() {
  isConfirmDisabled.value = !isConfirmChecked.value;
}

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      fetchNextInvoiceTotal();
    }
  }
);

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
